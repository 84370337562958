import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <div className='flex'>
            <div className='px-1 underline font-semibold cursor-pointer hover:opacity-90'><Link to={`/`}>Home</Link></div>
            <div className='px-1 underline font-semibold cursor-pointer hover:opacity-90'><Link to={`/result/`}>Result</Link></div>
        </div>
    );
}

export default Navbar;
