import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Analysis from './components/Analysis';
import { useState } from 'react';
import { useLocation } from "react-router-dom";


const Result = () => {
  const [isReult, setIsResult] = useState(false);
  const location = useLocation();

  if (!location.state) {
    return (
      <>
          <Header />
          <Navbar />
          <p className='text-center text-red-400 text-4xl py-72'>No Result</p>
          <Footer />
      </>
    );
  } else {
    return (
      <>
          <Header />
          <Navbar />
          {Object.keys(location.state).slice(0, -1).map((who) => 
            <Analysis 
              name={who} 
              positive={Intl.NumberFormat('ja', { style: 'percent'}).format(location.state[who]['positive'])} 
              negative={Intl.NumberFormat('ja', { style: 'percent'}).format(location.state[who]['negative'])} 
              neutral={Intl.NumberFormat('ja', { style: 'percent'}).format(location.state[who]['neutral'])} 
              mixed={Intl.NumberFormat('ja', { style: 'percent'}).format(location.state[who]['mixed'])} 
            />
          )}
      </>
    );
  };
};

export default Result;
