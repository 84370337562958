import Footer from './components/Footer';
import Form from './components/Form';
import Header from './components/Header';
import Navbar from './components/Navbar';


const Home = () => {
  return (
    <>
        <Header />
        <Navbar />
        <main className='pt-20 pb-36'>
            <Form />
        </main>
        <Footer />
    </>
  );
};

export default Home;