import React from 'react';

function Header(props) {
    return (
        <header className="App-header text-2xl md:text-4xl lg:text-4xl font-semibold text-white bg-cyan-700">
            <h1 className='p-3'>Team Atmosphere Estimation on Slack</h1>
        </header>
    );
  }

export default Header
