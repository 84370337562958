import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function Form(props) {
    const initialValues = { token: '', channel: '' };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isStartAnalysisSubmit, setIsStartAnalysisSubmit] = useState(false);
    const [isGetResultSubmit, setIsGetResultSubmit] = useState(false);
    const [isStatusCode, setIsStatusCode] = useState();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const startAnalysis = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsStartAnalysisSubmit(true);
        postRequest(formValues);
        setIsStatusCode();
    };

    const getResult = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsGetResultSubmit(true);
        getRequest(formValues);
    };

    const validate = (values) => {
        const errors = {};
        if (!values.token) {
            errors.token = 'Please input your Token'
        }
        if (!values.channel) {
            errors.channel = 'Please input your Channel ID'
        }

        return errors;
    };

    const postRequest = (values) => {
        if (Object.keys(formErrors).length === 0) {
            const data = {
                'bot_token': values.token,
                'channel_id': values.channel
            };

            axios.post('https://ixf9tdxbfe.execute-api.ap-northeast-1.amazonaws.com/test/analyze', data)
            .then(response => {
                console.log(response.data)
            }).catch(error => console.log(error))
        }
    };

    const getRequest = (values) => {
        if (Object.keys(formErrors).length === 0) {
            const data = {
                params: {
                    'bot_token': values.token,
                    'channel_id': values.channel
                }
            };

            axios.get('https://ixf9tdxbfe.execute-api.ap-northeast-1.amazonaws.com/test/analyze', data)
            .then(response => {
                console.log(response.data.status_code);
                setIsStatusCode(response.data.status_code);
                if (isStatusCode === 200) {
                    console.log(response.data.body.analyzed_data);
                    navigate('/result', { state: response.data.body.analyzed_data });
                };
            }).catch(error => console.log(error))
        }
    };

    return (
        <div>
            <div className='tokenForm text-center py-5'>
                <label className='text-amber-400 text-2xl font-semibold'>
                    Your Token<br/>
                    <input type="text" name="token" className='w-3/4 mb:w-1/2 lg:w-1/2 text-black' placeholder='Token' onChange={(e) => handleChange(e)} />
                </label>
                <p className='pb-8 text-red-400'>{formErrors.token}</p>
            </div>
            <div className='channelForm text-center py-5'>
                <label className='text-amber-400 text-2xl font-semibold'>
                    Your Channel ID<br/>
                    <input type="text" name="channel" className='w-3/4 mb:w-1/2 lg:w-1/2 text-black' placeholder='Channel ID' onChange={(e) => handleChange(e)} />
                </label>
                <p className='pb-8 text-red-400'>{formErrors.channel}</p>
            </div>
            <div className='text-center py-5 lg:flex lg:w-fit lg:m-auto  mb:flex mb:w-fit mb:m-auto'>
                <input className='bg-amber-400 font-semibold text-white text-2xl py-2 px-4 rounded cursor-pointer active:opacity-80 mb:mx-5 lg:mx-5 my-3' type="submit" value="Start Analysis" onClick={(e) => startAnalysis(e)} />
                <input className='bg-amber-400 font-semibold text-white text-2xl py-2 px-4 rounded cursor-pointer active:opacity-80 mb:mx-5 lg:mx-5 my-3' type="submit" value="Get Result" onClick={(e) => getResult(e)} />
            </div>
            {Object.keys(formErrors).length === 0 && isStartAnalysisSubmit && !isStatusCode && (
                <div className='text-center text-red-400'>Start Analysis!</div>
            )}
            {Object.keys(formErrors).length === 0 && isStatusCode === 202 && (
                <div className='text-center text-red-400'>Analysis in progress...<br />Please try to click 'Get Result' after a few minutes.</div>
            )}
            {Object.keys(formErrors).length === 0 && isStatusCode === 200 && (
                <div className='text-center text-red-400'>Analysis Success! Please click 'Get Result' again!</div>
            )}
        </div>
    );
  }

export default Form
