import React from 'react';

function Footer(props) {
    return (
        <footer className='bg-gray-200'>
            <p className="p-2 text-center text-xs">Copyright © 2022 Team 22 on Machine Intelligence Lecture. All Rights Reserved.</p>
        </footer>
    );
  }

export default Footer