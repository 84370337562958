import React from 'react';


const Analysis = (props) => {
    return (
        <>
            <div className='py-8 px-2'>
                <div className='bg-white w-fit p-5 border-2 border-dashed border-black m-auto rounded-lg'>
                    <p className='bg-white text-2xl text-center pb-3'>{props.name}</p>
                    <p className='bg-white text-1xl text-center'>Positive: {props.positive}</p>
                    <p className='bg-white text-1xl text-center'>Negative: {props.negative}</p>
                    <p className='bg-white text-1xl text-center'>Neutral: {props.neutral}</p>
                    <p className='bg-white text-1xl text-center'>Mixed: {props.mixed}</p>
                    {/* <div>message: 'おはよう'</div>
                    <div>{'{'}positive: 0.79, negative: 0.20{'}'}</div>
                    <div>message: 'こんにちは'</div>
                    <div>{'{'}positive: 0.62, negative: 0.37{'}'}</div>
                    <div>message: 'こんばんは'</div>
                    <div>{'{'}positive: 0.79, negative: 0.20{'}'}</div> */}
                </div>
            </div>
        </>
    );
};

export default Analysis;
